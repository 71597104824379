import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppService } from '@logic-suite/shared/app.service';

@Component({
  selector: 'app-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMenuComponent {
  private appService = inject(AppService);

  apps = this.appService.apps.value;
  activeAppID = toSignal(this.appService.application$);
}
